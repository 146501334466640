import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import {
  getOneXpExperiments,
  getHaveExperimentsBeenTracked
} from 'src/universal/redux/oneXp';

import { trackExperimentImpression } from 'src/universal/components/Experiments/utils/track-onexp-action';

import LazyScrollMetricTracker from 'src/universal/components/DTM/LazyScrollMetricTracker';

const TrackExperimentImpression = ({ experiment, haveExperimentsBeenTracked }) => {
  const [hasExperimentBeenImpressed, setHasExperimentBeenImpressed] = useState(
    false
  );
  const [
    hasExperimentImpressionBeenTracked,
    setHasExperimentImpressionBeenTracked
  ] = useState(false);

  useEffect(
    () => {
      if (!hasExperimentBeenImpressed || hasExperimentImpressionBeenTracked) return;
      if (haveExperimentsBeenTracked) {
        trackExperimentImpression(experiment);
        setHasExperimentImpressionBeenTracked(true);
      }
    },
    [
      hasExperimentBeenImpressed,
      hasExperimentImpressionBeenTracked,
      haveExperimentsBeenTracked,
      experiment
    ]
  );

  if (!experiment) {
    return null;
  }

  return (
    <LazyScrollMetricTracker
      callBackFunction={() => setHasExperimentBeenImpressed(true)}
    />
  );
};

const mapStateToProps = (state, { experimentName }) => ({
  experiment: getOneXpExperiments(state)?.[experimentName],
  haveExperimentsBeenTracked: getHaveExperimentsBeenTracked(state)
});

export default connect(mapStateToProps)(TrackExperimentImpression);
