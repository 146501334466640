import React from 'react';
import get from 'lodash.get';
import { connect } from 'react-redux';

import {
  Element,
  FontType,
  Align,
  Color,
  Type,
  Breakpoint,
  Button,
  Typography
} from '@americanexpress/aqx-components';
import LazyLoad from 'src/universal/components/DTM/LazyScrollMetricTracker';
import LazyScrollMetricTracker from 'src/universal/components/DTM/LazyScrollMetricTracker';

import parseArrayToObject from 'src/universal/utils/parseArrayToObject';
import {
  mapUrlWithDtmLinkQuery,
  sendDTMClickEvent
} from 'service_modules/models/dtm-tagging';

import { getProspectHeroData } from './redux';

import styles from './__styles__/index.scss?modules';

const CorpStartupBanner = ({ content }) => {
  if (!content) {
    return null;
  }

  const imgHash = parseArrayToObject(content.assets || [], 'breakpoint');

  const getImgUrl = breakpointVal => {
    const imgKeyHash = { sm: 'mobile', md: 'tablet', lg: 'desktop' };
    return (imgHash[imgKeyHash[breakpointVal]] || {}).url || '';
  };

  const ctaObj = (content.links || [])[0] || {};
  const ctaUrl = mapUrlWithDtmLinkQuery(ctaObj.url, ctaObj.dtm);

  const handleCtaClick = () => {
    sendDTMClickEvent(ctaObj.dtm);
  };

  return (
    <Breakpoint>
      {({ current, sm }) => {
        if (sm) {
          return null;
        }

        return (
          <Element className={styles.corpStartupBanner}>
            <LazyScrollMetricTracker
              offset={-100}
              scrollDtm={get(content, 'dtmEvents[0]')}
            />
            <LazyLoad>
              <img className={styles.imgBox} src={getImgUrl(current)} alt="" />
            </LazyLoad>
            <Element className={styles.contentBox}>
              <Typography
                Component="h2"
                $family={FontType.HelveticaBold}
                $size={'40px'}
                $lineHeight={'130%'}
                theme={{
                  textAlign: Align.Left,
                  color: Color.DeepBlue,
                  marginBottom: 10
                }}
              >
                {content.header}
              </Typography>
              <Typography
                $family={FontType.HelveticaRegular}
                $size={'18px'}
                $lineHeight="135%"
                theme={{
                  textAlign: Align.Left,
                  color: Color.DeepBlue,
                  marginBottom: 20
                }}
              >
                {content.description}
              </Typography>
              <Button
                Component="a"
                className={styles.ctaLearnMore}
                onClick={handleCtaClick}
                $type={Type.Primary}
                href={ctaUrl}
                title={ctaObj.text}
                aria-label={ctaObj['aria-label']}
              >
                {ctaObj.text}
              </Button>
            </Element>
          </Element>
        );
      }}
    </Breakpoint>
  );
};

const mapStateToProps = state => ({
  content: getProspectHeroData(state)
});

CorpStartupBanner.defaultProps = {
  qeId: 'CorpStartupBanner',
  customization: {}
};
export default connect(mapStateToProps)(React.memo(CorpStartupBanner));
