import React from 'react';
import { connect } from 'react-redux';

import {
  FontType,
  Align,
  Color,
  Breakpoint,
  Typography
} from '@americanexpress/aqx-components';

import {
  getBankingProductData,
  getShouldRenderBankingProductsBanner
} from './redux';

import styles from './__styles__/index.scss?modules';

const BankingProductsBanner = ({ content, shouldRender }) => {
  if (!content || !shouldRender) {
    return null;
  }
  const backgroundBannerURL = content.assets[0].url;
  const logoURL = content.assets[1].url;
  return (
    <Breakpoint>
      {({ sm }) => {
        if (sm) {
          return null;
        }
        return (
          <div className={styles.compWrapper}>
            <img
              src={backgroundBannerURL}
              alt=""
              className={styles.backgroundImage}
            />
            <div className={styles.innerBanner}>
              <img src={logoURL} alt="Business Blueprint" className={styles.logo} />
              <Typography
                $family={FontType.HelveticaBold}
                $size={'40px'}
                $lineHeight={'49px'}
                theme={{
                  textAlign: Align.Left,
                  color: Color.DeepBlue,
                  marginBottom: 10
                }}
              >
                {content.header}
              </Typography>
              <div className={styles.descriptionText}>
                <Typography
                  $family={FontType.HelveticaRegular}
                  $size={'18px'}
                  $lineHeight="24px"
                  theme={{
                    textAlign: Align.Left,
                    color: Color.DeepBlue,
                    marginBottom: 20
                  }}
                >
                  {content.description}
                </Typography>
              </div>
            </div>
          </div>
        );
      }}
    </Breakpoint>
  );
};

const mapStateToProps = state => ({
  content: getBankingProductData(state),
  shouldRender: getShouldRenderBankingProductsBanner(state)
});

export default connect(mapStateToProps)(React.memo(BankingProductsBanner));
