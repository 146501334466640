import React from 'react';
import { connect } from 'react-redux';
import { Element } from '@americanexpress/aqx-components';
import SpecialOffersView from './SpecialOffers';
import LazyScrollMetricTracker from 'src/universal/components/DTM/LazyScrollMetricTracker';
import { getPznOfferEligibleDtm } from 'src/universal/redux/page';
import { getSpecialOfferList } from 'src/universal/components/Experiments/G136/redux';
import { getCardColorInfoList } from 'src/universal/redux/cardColors';

// selectors
import { getDtmImpressions } from 'src/universal/redux/page';
import { getSpecialOffersData } from './redux';

// styling
import styles from './__styles__/index.scss?modules';

const SpecialOffersWrapper = ({
  content = {},
  impressions = {},
  eligibleOfferList,
  eligibleDtm,
  colorInfoList
}) => {
  if (!content?.needToRender) {
    return null;
  }

  const props = {
    content,
    eligibleDtmInfo: { eligibleOfferList, eligibleDtm },
    colorInfoList
  };

  return (
    <Element className={styles.SpecialOffersWrapper}>
      <LazyScrollMetricTracker scrollDtm={impressions['specialoffer']} />
      <SpecialOffersView {...props} />
    </Element>
  );
};

const mapStateToProps = state => ({
  content: getSpecialOffersData(state),
  impressions: getDtmImpressions(state),
  eligibleOfferList: getSpecialOfferList(state),
  eligibleDtm: getPznOfferEligibleDtm(state),
  colorInfoList: getCardColorInfoList(state)
});

export default connect(mapStateToProps)(React.memo(SpecialOffersWrapper));
