import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

// Components
import {
  Element,
  FontType,
  Align,
  Color,
  Type,
  BackgroundImage,
  Button,
  Typography,
  Breakpoint
} from '@americanexpress/aqx-components';
import LazyScrollMetricTracker from 'src/universal/components/DTM/LazyScrollMetricTracker';

// Selectors and Utils
import { getKabbageBannerData } from './redux';
import { isBrowser } from 'acq-utils/lib/misc';

// Styles
import styles from './__styles__/index.scss?modules';

const KabbageBanner = ({ kabbageData, mode }) => {
  const { shouldRender, content } = kabbageData;

  if (!shouldRender) {
    return null;
  }

  /*
  why do we conditionally fire the impression event here?
  (1) this component also gets rendered inside MobileAccordion.
  (2) but the instance inside MobileAccordion cannot have impression until the item is opened
      hence the mobile impression must be dealt with inside MobileAccordion.
  (3) as result we only need to cover non-mobile breakpoint here.
  */
  const needImpressionEvent = isBrowser() && window.innerWidth > 767;

  const {
    backgroundImageUrls,
    header,
    description,
    buttonText,
    buttonOnClick,
    buttonHref,
    logoImageUrl,
    impressionEvent,
    buttonAriaLabel,
    imageAlt
  } = content;
  return (
    <React.Fragment>
      {needImpressionEvent && (
        <LazyScrollMetricTracker scrollDtm={impressionEvent} />
      )}
      <Breakpoint>
        {({ current }) => {
          if (mode === 'desktop' && current === 'sm') {
            return null;
          }
          if (mode === 'mobile' && current !== 'sm') {
            return null;
          }
          const isSizeSmall = current === 'sm';

          return (
            <Element className={styles.container}>
              <BackgroundImage
                imageUrl={backgroundImageUrls[current].url}
                className={styles.backgroundImage}
              >
                <Typography
                  Component="h2"
                  $family={isSizeSmall ? FontType.BentonMedium : FontType.BentonBold}
                  $size="30px"
                  $lineHeight={isSizeSmall ? '28px' : '38px'}
                  theme={{
                    textAlign: isSizeSmall ? Align.Center : Align.Left,
                    color: Color.White,
                    marginBottom: 15
                  }}
                >
                  {header}
                </Typography>
                <Typography
                  Component="h3"
                  $family={FontType.HelveticaRegular}
                  $size="20px"
                  $lineHeight="28px"
                  theme={{
                    textAlign: isSizeSmall ? Align.Center : Align.Left,
                    color: Color.White,
                    marginBottom: isSizeSmall ? 50 : 30
                  }}
                  className={styles.description}
                >
                  {description}
                </Typography>
                <Element className={styles.buttonContainer}>
                  <Button
                    Component="a"
                    $type={Type.Secondary}
                    theme={{
                      horizontallyFluid: true,
                      marginBottom: 30,
                      focusLight: true
                    }}
                    href={buttonHref}
                    target="_blank"
                    onClick={buttonOnClick}
                    aria-label={buttonAriaLabel}
                  >
                    {buttonText}
                  </Button>
                </Element>
                <Element
                  className={styles.kabbageLogo}
                  Component="img"
                  src={logoImageUrl}
                  alt={imageAlt}
                />
              </BackgroundImage>
            </Element>
          );
        }}
      </Breakpoint>
    </React.Fragment>
  );
};

const mapStateToProps = state => {
  return {
    kabbageData: getKabbageBannerData(state)
  };
};

export default withRouter(connect(mapStateToProps)(KabbageBanner));
