import React from 'react';
import { connect } from 'react-redux';
import get from 'lodash.get';
import DesktopAndTablet from 'page_modules/business-hub/components/BusinessClassBanner/DesktopAndTablet';
import { Element, Breakpoint } from '@americanexpress/aqx-components';
import { Sanitizer } from 'acq-components/lib/base-components/Sanitizer/Sanitizer';
import Item from './ItemDisplay';
import CarouselWithPartialNextSlide from 'src/universal/components/CarouselWithPartialNextSlide';
import LazyScrollMetricTracker from 'src/universal/components/DTM/LazyScrollMetricTracker';

// styles
import styles from './__styles__/index.scss?modules';

// selectors
import { getBusinessClassBannerData } from 'page_modules/business-hub/components/BusinessClassBanner/redux';

const BusinessTrends = React.memo(trendData => {
  const { content, header, dtmEvents } = trendData;

  const headerImage = (
    <img
      className={styles.headerImage}
      src={get(trendData, 'assets[0].url') || ''}
      alt={get(trendData, 'assets[0].alt') || ''}
      loading="lazy"
    />
  );
  const bottomImage = (
    <img
      className={styles.bottomImage}
      src={get(trendData, 'assets[1].url') || ''}
      alt={get(trendData, 'assets[1].alt') || ''}
      loading="lazy"
    />
  );

  return (
    <div className={styles.businessTrendsWrap}>
      <LazyScrollMetricTracker scrollDtm={(dtmEvents || [])[0]} />

      <div className={styles.headerImgWrap}>{headerImage}</div>

      <Element Component="h2" className={styles.topHeader}>
        <Sanitizer safelySetInnerHTML={header} />
      </Element>

      <Breakpoint>
        {({ sm }) => {
          if (sm) {
            const slideList = (content || []).map((item, i) => {
              return <Item key={`k${i}`} {...item} />;
            });
            return <CarouselWithPartialNextSlide slideList={slideList} />;
          }

          return <DesktopAndTablet itemList={content} />;
        }}
      </Breakpoint>
      <div className={styles.lowerImgWrap}>{bottomImage}</div>
    </div>
  );
});

const mapStateToProps = state => ({
  ...getBusinessClassBannerData(state)
});

export default connect(mapStateToProps)(BusinessTrends);
