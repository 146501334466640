import React from 'react';
import { connect } from 'react-redux';

// Components
import {
  Element,
  FontType,
  Typography,
  Icon,
  Accordion
} from '@americanexpress/aqx-components';
import { Sanitizer } from 'acq-components/lib/base-components/Sanitizer/Sanitizer';
import LazyScrollMetricTracker from 'src/universal/components/DTM/LazyScrollMetricTracker';

// Selectors
import { getTermsAndConditionsData } from './redux';

// Enums/Vars/Etc and misc utils
import { sendDTMClickEvent } from 'service_modules/models/dtm-tagging';

// Styles
import styles from 'page_modules/business-hub/components/TermsAndConditions/index.scss?modules';

const RowWrapperButton = ({
  onClick,
  active,
  header,
  expandIcon,
  collapseIcon,
  dtmExpand,
  dtmCollapse
}) => {
  const clickHandler = param => {
    if (typeof onClick === 'function') {
      onClick(param);
    }
    sendDTMClickEvent(active ? dtmCollapse : dtmExpand);
  };
  return (
    <button onClick={clickHandler} aria-expanded={active} className={styles.button}>
      <Typography
        $family={FontType.HelveticaRegular}
        $size="14px"
        $lineHeight="16px"
        className={styles.header}
      >
        {header}
      </Typography>
      <Icon
        directory="dls"
        imageName={active ? collapseIcon : expandIcon}
        hideIconToScreenReaders={true}
      />
    </button>
  );
};

const Body = ({ content }) => (
  <Element Component="ul" className={styles.body}>
    {content.map((item, i) => (
      <Sanitizer
        key={`terms-and-conditions-list-item-${i}`}
        Component="li"
        className={styles.listItem}
        safelySetInnerHTML={item?.desc?.[0]?.text}
      />
    ))}
  </Element>
);

const TermsAndConditions = ({ data }) => {
  const { header, content, expandIcon, collapseIcon, dtmEvents } = data;
  const getDtmByKey = key => (dtmEvents || []).find(dtm => dtm.key === key) || {};
  const impressionDtm = getDtmByKey('tncImpression');
  const dtmExpand = getDtmByKey('accordionExpand');
  const dtmCollapse = getDtmByKey('accordionCollapse');

  return (
    <React.Fragment>
      <LazyScrollMetricTracker scrollDtm={impressionDtm} />
      <Element className={styles.container}>
        <Accordion
          Button={({ onClick, active }) => {
            const props = {
              onClick,
              active,
              header,
              expandIcon,
              collapseIcon,
              dtmExpand,
              dtmCollapse
            };
            return <RowWrapperButton {...props} />;
          }}
          Body={() => <Body content={content} />}
        />
      </Element>
    </React.Fragment>
  );
};

const mapStateToProps = state => ({ data: getTermsAndConditionsData(state) });

export default connect(mapStateToProps)(TermsAndConditions);
