import React from 'react';
import { connect } from 'react-redux';

// selectors
import { getProspectHeroData } from './redux';
import { hasMemberInfoArrived } from 'src/universal/redux/cardMemberInfo';
import { isProspect } from 'src/universal/redux/userType';
import { isGCSMember, getCardMemberCards } from 'src/universal/redux/cardMemberInfo';

// components
import HeroWaitingModule from 'src/universal/components/CmTabbedHero/HeroWaitingModule';
import CmTabbedHero from 'src/universal/components/CmTabbedHero';
import ProspectHero from './ProspectHero';

// styles
import tabbedHeroHubPageStyles from 'src/universal/components/CmTabbedHero/__styles__/hubPageOverrides.scss?modules';

const HubHero = ({
  cmToolTipToggle = () => {},
  prospectHeroData,
  spinnerAriaText,
  initialEEP,
  hasCmInfoArrived,
  isUserProspect,
  isUserCm,
  userCardList
}) => {
  if (!hasCmInfoArrived && !isUserProspect) {
    return (
      <div role="alert" aria-live="assertive">
        <HeroWaitingModule ariaLabelText={spinnerAriaText} />
      </div>
    );
  }

  if (isUserCm && Array.isArray(userCardList) && userCardList.length > 0) {
    return (
      <div className={tabbedHeroHubPageStyles.container}>
        <div className={tabbedHeroHubPageStyles.backgroundImageHolder} />
        <CmTabbedHero
          initialEEP={initialEEP}
          cmToolTipToggle={cmToolTipToggle}
          customStyles={tabbedHeroHubPageStyles}
          isBussinessHub={true}
        />
      </div>
    );
  }

  return <ProspectHero content={prospectHeroData} />;
};

const mapStateToProps = state => {
  return {
    ...getProspectHeroData(state),
    hasCmInfoArrived: hasMemberInfoArrived(state),
    isUserCm: isGCSMember(state),
    isUserProspect: isProspect(state),
    userCardList: getCardMemberCards(state)
  };
};

export default connect(mapStateToProps)(React.memo(HubHero));
