import React from 'react';
import { connect } from 'react-redux';

import { getTwoUpData, getShouldRenderTwoUp } from './redux';
import {
  Element,
  FontType,
  Color,
  Breakpoint,
  Box,
  Button,
  Typography
} from '@americanexpress/aqx-components';
import LazyLoad from 'src/universal/components/DTM/LazyScrollMetricTracker';

import { getUrlWithOriginPrepended } from 'core_modules/utils/url.utils';

import styles from './__styles__/index.scss?modules';

const NonCardTile = ({
  header,
  buttons,
  assets: [desktopAsset, tabletAsset, cornerAsset]
}) => {
  return (
    <Breakpoint>
      {({ sm, md, lg }) => {
        if (sm) {
          return null;
        }

        const neededAsset = lg ? desktopAsset : md ? tabletAsset : null;
        return (
          <Box $shadow="light" className={styles.NonCardTile}>
            <LazyLoad>
              <Element
                Component="img"
                src={neededAsset?.url}
                className={styles.mainImage}
                alt=""
              />
            </LazyLoad>
            <div className={styles.textContent}>
              <LazyLoad>
                <Element
                  Component="img"
                  src={cornerAsset?.url}
                  className={styles.cornerImage}
                  alt=""
                />
              </LazyLoad>
              <Typography
                Component="h3"
                $family={FontType.BentonLight}
                theme={{
                  color: Color.DeepBlue
                }}
                $size={30}
                className={styles.header}
              >
                {header}
              </Typography>
              {buttons.map((button, i) => (
                <Button
                  Component="a"
                  href={getUrlWithOriginPrepended(button.url)}
                  key={`2up-button-${i}`}
                  aria-label={button.label}
                  theme={{
                    focusDark: true
                  }}
                >
                  {button.text}
                </Button>
              ))}
            </div>
          </Box>
        );
      }}
    </Breakpoint>
  );
};

const NoncardTwoUp = ({ twoUpData, shouldRender }) => {
  if (!shouldRender) {
    return null;
  }
  return (
    <div className={styles.NonCardTwoUp}>
      {twoUpData.content.map((data, i) => (
        <NonCardTile key={`2up-tile-${i}`} {...data} />
      ))}
    </div>
  );
};

const mapStateToProps = state => {
  return {
    twoUpData: getTwoUpData(state),
    shouldRender: getShouldRenderTwoUp(state)
  };
};

export default connect(mapStateToProps)(NoncardTwoUp);
