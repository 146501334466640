/*
US4142960:
The following was copied over from the following module without modification/refactoring:
  page_modules/business-home/components/SpecialOffers/index.js
This is to ensure that the original styling/behavior is completely intact.
*/

import React, { useMemo, useState, useEffect, useRef } from 'react';
import get from 'lodash.get';
import { Link } from 'acq-components/lib/base-components/Link/Link';
import { Sanitizer } from 'acq-components/lib/base-components/Sanitizer/Sanitizer';
import { HeroBanner } from 'acq-components/lib/composite-components/HeroBanner/HeroBanner';
import { SbsSecondaryContainer } from 'acq-components/lib/composite-components/HeroBanner/variants/IntlCommercial/SbsSecondaryContainer';
import { CustPrimaryContainer } from './CustPrimaryContainer';
import specialOffersHeroStyles from 'acq-components/lib/composite-components/HeroBanner/styles/sbsHomePageSpecialOffers.scss?modules';
import {
  Element,
  Color,
  Size,
  Align,
  Carousel,
  Icon
} from '@americanexpress/aqx-components';
import ColorPicker, { parent } from 'src/universal/components/ColorPicker';
import {
  mapUrlWithDtmLinkQuery,
  sendDTMClickEvent,
  sendDTMCustomEvent
} from 'service_modules/models/dtm-tagging';
import needPznEligibleEvent from 'src/universal/utils/needPznEligibleEvent';
import tagStrip from 'service_modules/utils/text-remove-tags.js';
import { offerGatingForm } from 'page_modules/utils/offer-gating';
import { getApplyButtonObj } from 'service_modules/models/shared/cross-model-helper';
import { StrikethroughOffer } from 'src/universal/components/Strikethrough';
import replaceStringWithVars from 'src/universal/utils/replaceStringWithVars';
import LazyScrollMetricTracker from 'src/universal/components/DTM/LazyScrollMetricTracker';
import { decideCardArtUrl } from 'src/universal/utils/cardColorChoices';

import './__styles__/specialOffers.scss';
const { Container, Prev, Next, Dots, Items, Item } = Carousel;
const buildBodyLines = dataItem => {
  return ['bodyLine1', 'bodyLine2'].reduce((accumulator, key) => {
    const content = dataItem[key];
    if (content) {
      accumulator.push({
        leftCell: <Sanitizer Component="div" safelySetInnerHTML={content} />,
        rightCell: ''
      });
    }
    return accumulator;
  }, []);
};

const buildButtonUrl = (targetData = {}, dtmKey) => {
  const { url, dtmTag = {} } = targetData;
  const dtmTagVal = {
    ...dtmTag,
    tag: dtmKey
  };
  return mapUrlWithDtmLinkQuery(url, dtmTagVal);
};

const createGridProps = dataItem => {
  return {
    gridStyles: {},
    styles: specialOffersHeroStyles,
    content: buildBodyLines(dataItem),
    title: <Sanitizer Component="div" safelySetInnerHTML={dataItem.cardTitle} />,
    subtitle: <StrikethroughOffer Component="div" header={dataItem.subTitle} />
  };
};

const buildTncLinks = (cardInfo, eep, labelsHash, dtmList) => {
  // if both benefitTerms and offerTerms are present, we remove the benefitTerms
  const tncLinksBase = cardInfo.tncLinks || {};
  const isBothPresent = !!tncLinksBase.offerTerms && !!tncLinksBase.benefitTerms;
  const tncCopy = { ...tncLinksBase };
  if (isBothPresent) {
    delete tncCopy.benefitTerms;
  }
  const dtmClickHandler = (dtmData, key, product) =>
    sendDTMClickEvent({
      ...dtmData,
      eventAsset: `${key}-${product}`
    });

  return (
    <div className="tncWrap">
      {cardInfo.tncLinks &&
        Object.keys(tncCopy).map((key, index) => {
          const tncObj = cardInfo.tncLinks[key];
          const dtmKey = key.toLowerCase();
          const dtmValue = dtmList[dtmKey] || {};
          if (!tncObj || !labelsHash[key]) {
            return null;
          }

          const { url = '', urlAppend = '' } = tncObj;
          return (
            <Sanitizer
              key={`k${index}`}
              className="tncLink"
              target="_blank"
              to={`${url}${eep}${urlAppend}`}
              Component={Link}
              onClick={() => dtmClickHandler(dtmValue, dtmKey, cardInfo.shortName)}
              safelySetInnerHTML={labelsHash[key]}
              aria-label={`${tagStrip(labelsHash[key])} for ${tagStrip(
                cardInfo.cardHeadline
              )}`}
            />
          );
        })}
    </div>
  );
};

const buildHeroList = ({
  offerDataList,
  cardList,
  labelsHash,
  pageId,
  dtmList,
  colorInfoList
}) => {
  return offerDataList.map((offerItem, index) => {
    const cardInfo = cardList.find(card => card.pmcCode === offerItem.pmcCode) || {};
    const colorInfo = (colorInfoList || []).find(
      c => c.shortName === offerItem.shortName
    );
    const feeDisclaimer = colorInfo?.rubiksContent?.annualFeeDisclaimer;
    const disclaimerPart = feeDisclaimer
      ? `<div class='disclaimer'>${feeDisclaimer}</div>`
      : '';
    const cardArtUrl = get(cardInfo, 'appAssets.cardArt');
    const cardArtUrlToUse = decideCardArtUrl(
      cardArtUrl,
      colorInfo,
      offerItem.shortName
    );
    const gridProps = createGridProps({
      cardTitle: `<h3>${cardInfo.cardHeadline}</h3>`,
      subTitle: offerItem.header,
      bodyLine1: offerItem.description ? offerItem.description : undefined,
      bodyLine2: cardInfo.annualFee
        ? `<b>${labelsHash.annualFee}</b> ${cardInfo.annualFee}${disclaimerPart}`
        : undefined
    });
    const cardCopy = {
      ...cardInfo,
      apply: {
        ...cardInfo.apply,
        url: buildButtonUrl(cardInfo.apply, 'Offer-Apply', cardInfo.shortName)
      },
      pznOffer: { ...offerItem }
    };
    const applyButtonObj = getApplyButtonObj(cardCopy.apply, cardCopy, pageId);
    const { cardHeadline } = cardCopy || {};
    return (
      <HeroBanner
        key={`hero${index}`}
        PrimaryContentComponent={CustPrimaryContainer}
        SecondaryContentComponent={SbsSecondaryContainer}
        primaryContentProps={{
          image: {
            src: cardArtUrlToUse,
            alt: tagStrip(cardInfo.cardArtAltText)
          },
          componentUnderImg: (
            <ColorPicker
              shortName={offerItem.shortName}
              parentType={parent.BHUB_OFFER}
            />
          ),
          buttonsContainerClassname: specialOffersHeroStyles.buttonsContainer,
          primaryButtonProps: {
            children: labelsHash.applyNow,
            className: specialOffersHeroStyles.applyButton,
            onClick: applyButtonObj.onClick,
            'aria-label': `${labelsHash.applyNow} for ${tagStrip(cardHeadline)}`
          },
          secondaryButtonProps: {
            children: 'View Details',
            className: specialOffersHeroStyles.callButton,
            onClick: () => {
              const urlVal = buildButtonUrl(cardInfo.learnMore, 'Offer-Details');
              window.location.href = urlVal;
            },
            'aria-label': `View Details for ${tagStrip(cardHeadline)}`
          },
          textComponents: {
            tncLink: buildTncLinks(cardInfo, offerItem.eep, labelsHash, dtmList)
          },
          textComponentsStyles: {
            phoneNumber: specialOffersHeroStyles.phoneNumber,
            tncLink: specialOffersHeroStyles.tncLink
          },
          styles: specialOffersHeroStyles,
          gridProps
        }}
        secondaryContentProps={{
          TitleComponent: 'span',
          gridStyles: {},
          styles: specialOffersHeroStyles,
          content: [],
          title: '',
          subtitle: ''
        }}
        styles={specialOffersHeroStyles}
      />
    );
  });
};

const buildPaddleIcon = isLeft => (
  <Icon
    directory="dls"
    imageName={isLeft ? 'dls-icon-left' : 'dls-icon-right'}
    $size={Size.SM}
    hiddenToScreenReaders="true"
    theme={{
      paddingRight: 0,
      paddingLeft: 0,
      color: Color.BrightBlue
    }}
  />
);

const getEligibleDtm = (card = {}, eligibleOffers = [], pznEligibleDtm) => {
  const matchedOffer = eligibleOffers.find(o => o.shortName === card.shortName);
  const prefixVal = matchedOffer ? 'special_' : '';
  const assetVal = replaceStringWithVars({
    varsObject: { productName: `${prefixVal}${card.shortName}` },
    string: pznEligibleDtm.eventAsset || ''
  });
  return {
    ...pznEligibleDtm,
    eventAsset: assetVal
  };
};

// we only need to process each index once.
const processedIndexList = [];

const SpecialOffers = React.memo(
  ({
    content: {
      specialOffersData = [],
      cards = [],
      labelsHash = {},
      pageId = '',
      dtmList = []
    },
    eligibleDtmInfo: { eligibleOfferList, eligibleDtm },
    colorInfoList
  }) => {
    const allowSlideFocus = useRef(false);
    const [lazyloadDone, setLazyloadDone] = useState(false);
    const [activeIndex, setActiveIndex] = useState(0);

    const onLazyload = () => {
      setLazyloadDone(true);
    };

    const componentList = useMemo(
      () =>
        buildHeroList({
          offerDataList: specialOffersData,
          cardList: cards,
          labelsHash,
          pageId,
          dtmList,
          colorInfoList
        }),
      [specialOffersData, cards, labelsHash, pageId, dtmList, colorInfoList]
    );

    const paddleDTMHandler = key => {
      sendDTMClickEvent({
        ...(dtmList['carousel'] || {}),
        eventAsset: `${get(dtmList, 'carousel.eventAsset')}-${key}`
      });
    };

    const handleNavClick = state => {
      const indexNew = state?.currentIndex;

      if (activeIndex !== indexNew) {
        setActiveIndex(indexNew);
      }
    };

    const needEligibleEvent = (card = {}) => {
      return needPznEligibleEvent(specialOffersData, card.shortName);
    };

    useEffect(() => {
      setTimeout(() => {
        // we only need to do this one time.
        allowSlideFocus.current = true;
      }, 1000);
    }, []);

    useEffect(
      () => {
        if (lazyloadDone && processedIndexList.indexOf(activeIndex) <= -1) {
          processedIndexList.push(activeIndex);
          const cardOffer = specialOffersData[activeIndex];
          if (needEligibleEvent(cardOffer)) {
            const impressionDtm = getEligibleDtm(
              cardOffer,
              eligibleOfferList,
              eligibleDtm
            );
            setTimeout(() => {
              sendDTMCustomEvent(impressionDtm);
            }, 200);
          }
        }
      },
      [activeIndex, lazyloadDone]
    );

    return (
      <div className="specialOffersOuterWrap">
        {offerGatingForm}
        <LazyScrollMetricTracker callBackFunction={onLazyload} />
        <div className="specialOffersWrap">
          <Sanitizer
            className="topHeader"
            safelySetInnerHTML={labelsHash.specialOffersHeader}
            Component="h2"
          />
          <Container totalItems={componentList.length}>
            <Items onSwipe={handleNavClick}>
              {componentList.map((card, index) => {
                const isInactive = index !== activeIndex;
                return (
                  <Item
                    className={isInactive ? 'contentInactive' : 'contentActive'}
                    key={index}
                    index={index}
                    allowFocus={allowSlideFocus.current}
                  >
                    {card}
                  </Item>
                );
              })}
            </Items>
            {componentList.length > 1 && (
              <Element className="sliderWrap">
                <Prev
                  className="paddleLeft"
                  onClick={state => {
                    paddleDTMHandler('Left');
                    handleNavClick(state);
                  }}
                >
                  {buildPaddleIcon(true)}
                </Prev>
                <Element
                  className="dotsWrap"
                  theme={{
                    margin: 0,
                    padding: 0,
                    textAlign: Align.Center
                  }}
                >
                  <Dots
                    inactive={
                      <Element
                        Component="span"
                        className="dot"
                        theme={{
                          marginLeft: 0,
                          marginRight: 0,
                          bgColor: Color.Gray03
                        }}
                      />
                    }
                    active={
                      <Element
                        Component="span"
                        className="dot"
                        theme={{
                          marginLeft: 0,
                          marginRight: 0,
                          bgColor: Color.BrightBlue
                        }}
                      />
                    }
                    clickAble={true}
                    onClick={handleNavClick}
                  />
                </Element>
                <Next
                  className="paddleRight"
                  onClick={state => {
                    paddleDTMHandler('Right');
                    handleNavClick(state);
                  }}
                >
                  {buildPaddleIcon(false)}
                </Next>
              </Element>
            )}
          </Container>
        </div>
      </div>
    );
  }
);

export default SpecialOffers;
