import { createSelector } from 'reselect';
import { getPageData, getPageFeatureFlags } from 'src/universal/redux/page';

export const getTwoUpData = createSelector([getPageData], pageData => {
  return pageData.nonCardTwoUp;
});

export const getShouldRenderTwoUp = createSelector(
  [getPageFeatureFlags],
  featureFlags => featureFlags.showBankingProductsTiles
);
