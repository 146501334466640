import get from 'lodash.get';
import parseArrayToObject from 'src/universal/utils/parseArrayToObject';
import parseAssetsToBreakpoints from 'src/universal/utils/assetsToBreakpointMap';
import {
  sendDTMClickEvent,
  mapUrlWithDtmLinkQuery
} from 'service_modules/models/dtm-tagging';

export const formatKabbageContent = (content = {}) => {
  const assets = parseAssetsToBreakpoints(content.assets);
  const dtmEvents = parseArrayToObject(content.dtmEvents, 'eventAction');
  const button = get(content, 'buttons[0]') || {};
  const ctaDtm = button.dtm || {};
  const buttonHref = mapUrlWithDtmLinkQuery(button.url, ctaDtm);
  return {
    backgroundImageUrls: parseArrayToObject(assets, 'breakpoint'),
    header: content.header,
    description: get(content, 'desc[0].text') || '',
    buttonText: button.title || '',
    buttonAriaLabel: button?.ariaLabel ?? '',
    buttonHref,
    buttonOnClick: () => {
      sendDTMClickEvent(ctaDtm);
    },
    logoImageUrl: content.imageUrl,
    imageAlt: content.imageAlt,
    impressionEvent: dtmEvents.impression
  };
};
