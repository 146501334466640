import get from 'lodash.get';
import React, { useLayoutEffect, useRef } from 'react';
import {
  Element,
  FontType,
  Align,
  Color,
  BackgroundImage,
  Breakpoint,
  Typography
} from '@americanexpress/aqx-components';
import LazyScrollMetricTracker from 'src/universal/components/DTM/LazyScrollMetricTracker';
import styles from './__styles__/prospectHero.scss?modules';

const ProspectHero = ({ content }) => {
  const backImageReady = useRef(false);

  useLayoutEffect(() => {
    backImageReady.current = true;
  }, []);

  if (!content) {
    return null;
  }

  return (
    <Element className={styles.prospectHero}>
      <LazyScrollMetricTracker scrollDtm={get(content, 'dtmEvents[0]') || {}} />
      <Breakpoint>
        {({ current }) => {
          const isNarrow = current === 'sm';
          const backImgUrl =
            typeof window === 'object' && window.innerWidth < 768
              ? get(content, 'assets[0].url')
              : get(content, 'assets[1].url');
          return (
            <BackgroundImage
              imageUrl={backImageReady.current ? backImgUrl : ''}
              className={styles.backImage}
            >
              <Element className={styles.contentBox}>
                <Typography
                  className={styles.title}
                  Component="h1"
                  $family={FontType.HelveticaRegular}
                  $size={isNarrow ? '22px' : '24px'}
                  $lineHeight="130%"
                  theme={{
                    textAlign: Align.Left,
                    color: Color.White,
                    marginBottom: 10
                  }}
                >
                  {content.title}
                </Typography>
                <Typography
                  className={styles.header}
                  Component="h2"
                  $family={FontType.HelveticaBold}
                  $size={isNarrow ? '28px' : '30px'}
                  $lineHeight="130%"
                  theme={{
                    textAlign: Align.Left,
                    color: Color.White,
                    marginBottom: 10
                  }}
                >
                  {content.header}
                </Typography>
                <Typography
                  Component="h3"
                  $family={FontType.HelveticaRegular}
                  $size={isNarrow ? '16px' : '18px'}
                  $lineHeight="130%"
                  theme={{
                    textAlign: Align.Left,
                    color: Color.White,
                    marginBottom: 10
                  }}
                >
                  {content.description}
                </Typography>
              </Element>
            </BackgroundImage>
          );
        }}
      </Breakpoint>
    </Element>
  );
};

export default React.memo(ProspectHero);
