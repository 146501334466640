import { createSelector } from 'reselect';
import { getPageData, getPageGlobalData } from 'src/universal/redux/page';

export const getProspectHeroData = createSelector(
  [getPageData, getPageGlobalData],
  (pageData, globalData) => {
    return {
      prospectHeroData: pageData.prospectHero,
      spinnerAriaText: pageData.accessibility?.spinnerText || '',
      initialEEP: globalData?.globalHeaderPage?.defaultEEP || ''
    };
  }
);
