import React from 'react';
import { Button } from 'acq-components/lib/base-components/Button/Button';
import { Typography } from 'acq-components/lib/base-components/Typography/Typography';
import { NS } from 'acq-components/lib/utils/PropertyProvider/PropertyProvider';
import { SbsSecondaryContainer } from 'acq-components/lib/composite-components/HeroBanner/variants/IntlCommercial/SbsSecondaryContainer';

const CustPrimaryContainer = ({
  styles,
  image,
  componentUnderImg,
  gridProps,
  primaryButtonProps,
  secondaryButtonProps,
  textComponents,
  textComponentsProps,
  textComponentsStyles,
  namespace
}) => (
  <div>
    <div className={styles.imageWrapper}>
      <img src={image.src} alt={image.alt} className={styles.image} />
      {componentUnderImg}
    </div>
    <SbsSecondaryContainer {...gridProps} TitleComponent="div" />
    <div className={styles.buttonsContainer}>
      {!!primaryButtonProps &&
        primaryButtonProps.children && (
          <Button {...primaryButtonProps} namespace={NS(namespace, 'primary')} />
        )}
      {!!secondaryButtonProps &&
        secondaryButtonProps.children && (
          <Button {...secondaryButtonProps} namespace={NS(namespace, 'secondary')} />
        )}
    </div>
    <Typography
      components={textComponents}
      styles={textComponentsStyles}
      componentProps={textComponentsProps}
    />
  </div>
);

export { CustPrimaryContainer };
