import { createSelector } from 'reselect';
import { getPageData, getPageFeatureFlags } from 'src/universal/redux/page';

export const getBankingProductData = createSelector([getPageData], pageData => {
  return {
    ...pageData.bankingProductsBanner
  };
});

export const getShouldRenderBankingProductsBanner = createSelector(
  [getPageFeatureFlags],
  featureFlags => featureFlags.showBankingProductsBanner
);
