import React from 'react';
import { connect } from 'react-redux';

// Components
import {
  Element,
  FontType,
  Size,
  Color,
  Breakpoint,
  Button,
  Typography,
  Icon,
  Accordion
} from '@americanexpress/aqx-components';
import { LazyLoad } from 'acq-components/lib/services/performance/LazyLoad/LazyLoad';
import KabbageBanner from '../KabbageBanner';
import LazyScrollMetricTracker from 'src/universal/components/DTM/LazyScrollMetricTracker';

// Selectors
import { getMobileAccordionData } from './redux';

// Utils
import { getUrlWithOriginPrepended } from 'core_modules/utils/url.utils';
import { isBrowser } from 'acq-utils/lib/misc';
import clsx from 'clsx';
import {
  mapUrlWithDtmLinkQuery,
  sendDTMClickEvent,
  sendDTMCustomEvent
} from 'service_modules/models/dtm-tagging';

// Styles
import styles from './__styles__/index.scss?modules';

/*
why this module level func?
(1) we need to fire impression as each accordion item expands if it is defined.
(2) if so, we only need to fire once for impression event.
*/

const doneList = [];

const doImpression = panelList => {
  if (!isBrowser() || window.innerWidth > 767) {
    return;
  }

  const impressionList = (panelList || []).reduce((accumulator, panel) => {
    if (panel.impressionDtm) {
      accumulator.push(panel.impressionDtm);
    }
    return accumulator;
  }, []);

  if (impressionList.length > 0) {
    impressionList.forEach(dtm => {
      if (doneList.indexOf(dtm.eventAsset) <= -1) {
        doneList.push(dtm.eventAsset);
        sendDTMCustomEvent(dtm);
      }
    });
  }
};

const Header = ({
  onClick,
  active,
  content,
  ariaControlsId,
  panels,
  headerClickDtm
}) => {
  const clickHandler = param => {
    if (!active) {
      doImpression(panels);
    }
    if (typeof onClick === 'function') {
      onClick(param);
    }
    sendDTMClickEvent(headerClickDtm);
  };
  return (
    <Element Component="h2">
      <Element
        Component="button"
        onClick={clickHandler}
        className={styles.accordionButton}
        aria-expanded={active}
        aria-controls={ariaControlsId}
      >
        <Icon
          className={styles.leftIcon}
          $size={Size.LG}
          hiddenToScreenReaders={true}
          directory="dls"
          imageName={content.icon}
        />
        <Typography
          className={styles.buttonText}
          $family={FontType.BentonRegular}
          $size="16px"
          $lineHeight="22px"
        >
          {content.text}
        </Typography>
        <Icon
          className={styles.rightIcon}
          hiddenToScreenReaders={true}
          directory="dls"
          imageName={active ? 'dls-icon-down' : 'dls-icon-right'}
          $size={Size.SM}
        />
      </Element>
    </Element>
  );
};

const Panels = ({ panels, active, ariaControlsId }) => {
  return (
    <Element className={styles.panels}>
      {panels.map(
        (
          {
            imgSrc,
            imgAlt,
            imgHeight,
            logoSrc,
            logoAlt,
            heading,
            text,
            linkText,
            href,
            linkAriaLabel,
            showLocModule,
            dtm
          },
          index
        ) => {
          if (showLocModule) {
            return <KabbageBanner mode="mobile" key={index} />;
          }
          const urlVal = mapUrlWithDtmLinkQuery(href, dtm);
          return (
            <Element className={styles.panel} key={index} id={ariaControlsId}>
              {imgSrc &&
                active && (
                  <LazyLoad>
                    <Element
                      className={styles.img}
                      Component="img"
                      height={imgHeight}
                      src={imgSrc}
                      alt={imgAlt}
                    />
                  </LazyLoad>
                )}
              {logoSrc && (
                <Element
                  className={styles.logo}
                  Component="img"
                  src={logoSrc}
                  alt={logoAlt}
                />
              )}
              {heading && (
                <Typography
                  className={clsx(styles.heading, {
                    [styles.boldHeader]: text
                  })}
                  Component="h3"
                  $family={text ? FontType.HelveticaRegular : FontType.BentonBook}
                  $size={text ? '24px' : '30px'}
                  $lineHeight={text ? '29px' : '38px'}
                  theme={{ color: Color.DeepBlue, marginTop: !text && '5px' }}
                >
                  {heading}
                </Typography>
              )}
              {text && (
                <Typography
                  className={styles.text}
                  $family={FontType.HelveticaRegular}
                  $size="18px"
                  $lineHeight="22px"
                  theme={{ color: Color.DeepBlue }}
                >
                  {text}
                </Typography>
              )}
              {linkText && (
                <Button
                  className={styles.button}
                  Component="a"
                  href={getUrlWithOriginPrepended(urlVal)}
                  aria-label={linkAriaLabel}
                  onClick={() => sendDTMClickEvent(dtm)}
                >
                  {linkText}
                </Button>
              )}
            </Element>
          );
        }
      )}
    </Element>
  );
};

const MobileAccordion = ({ content, dtmEvents }) => {
  return (
    <Breakpoint>
      {({ sm }) => {
        if (!sm || content.length < 1) {
          return null;
        }
        return (
          <React.Fragment>
            <LazyScrollMetricTracker scrollDtm={(dtmEvents || [])[0]} />
            <Element className={styles.wrapper}>
              {content.map((item, index) => {
                const ariaControlsId = `mobile-sub-nav-panel-${index}`;
                if (item.active) {
                  doImpression(item.panels);
                }
                return (
                  <Accordion
                    key={index}
                    initialState={item.active}
                    Button={({ onClick, active }) => (
                      <Header
                        onClick={onClick}
                        active={active}
                        content={item.header}
                        ariaControlsId={ariaControlsId}
                        panels={item.panels || []}
                        headerClickDtm={item.headerClickDtm}
                      />
                    )}
                    Body={({ active }) => (
                      <Panels
                        panels={item.panels || []}
                        active={active}
                        ariaControlsId={ariaControlsId}
                      />
                    )}
                  />
                );
              })}
            </Element>
          </React.Fragment>
        );
      }}
    </Breakpoint>
  );
};

const mapStateToProps = state => ({
  ...getMobileAccordionData(state)
});

export default connect(mapStateToProps)(React.memo(MobileAccordion));
