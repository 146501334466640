import React, { useState, useRef, useCallback, useEffect } from 'react';
import clsx from 'clsx';
import {
  Element,
  Color,
  Size,
  Align,
  Breakpoint,
  Icon,
  Carousel
} from '@americanexpress/aqx-components';
import defautStyles from './__styles__/index.scss?modules';

const { Container, Next, Prev, Dots, Items, Item } = Carousel;

const buildPaddleIcon = isLeft => (
  <Icon
    directory="dls"
    imageName={isLeft ? 'dls-icon-left' : 'dls-icon-right'}
    $size={Size.SM}
    theme={{
      paddingRight: 0,
      paddingLeft: 0,
      color: Color.Gray06
    }}
  />
);

const CarouselWithPartialNextSlide = ({
  slideList,
  customization,
  custStyles,
  onActiveIndexChange,
  rotationIndex
}) => {
  const { initialActiveItem, partialSlideWidth } = customization;
  const [activeIndex, setActiveIndex] = useState(initialActiveItem);
  const currentIndex = useRef(initialActiveItem);
  const allowSlideFocus = useRef(false);

  useEffect(
    () => {
      if (rotationIndex !== activeIndex) {
        setActiveIndex(rotationIndex);
      }
    },
    [rotationIndex]
  );

  useEffect(() => {
    setTimeout(() => {
      allowSlideFocus.current = true;
    }, 1000);
  }, []);

  const createOnItemClick = useCallback(
    index => {
      return () => {
        if (index !== currentIndex.current) {
          let direction = 'next';
          if (currentIndex.current > index) {
            direction = 'prev';
          }
          currentIndex.current = index;
          setActiveIndex(index);
          onActiveIndexChange(index, direction);
        }
      };
    },
    [currentIndex]
  );

  const calcDirection = state => {
    return state.currentIndex > state.prevIndex ? 'next' : 'prev';
  };

  const handleIndexChange = useCallback(
    (currState, direction) => {
      currentIndex.current = currState.currentIndex;
      setActiveIndex(currState.currentIndex);
      const directionVal =
        direction !== 'prev' && direction !== 'next'
          ? calcDirection(currState)
          : direction;
      onActiveIndexChange(currState.currentIndex, directionVal);
    },
    [currentIndex]
  );

  const moveSlide = (nextIndex, direction) => {
    setActiveIndex(nextIndex);
    currentIndex.current = nextIndex;
    onActiveIndexChange(nextIndex, direction);
  };

  const handleKeyDown = evt => {
    const event = evt;
    const { key } = event;
    const lastIndex = (slideList || []).length - 1;
    const hasSlides = lastIndex >= 0;
    let needStopPropagation = false;
    let nextIndex;

    if (hasSlides && (key === 'ArrowLeft' || key === 'Left')) {
      nextIndex = currentIndex.current - 1;
      if (nextIndex >= 0) {
        moveSlide(nextIndex, 'prev');
        needStopPropagation = true;
      }
    } else if (hasSlides && (key === 'ArrowRight' || key === 'Right')) {
      nextIndex = currentIndex.current + 1;
      if (nextIndex <= lastIndex) {
        moveSlide(nextIndex, 'next');
        needStopPropagation = true;
      }
    }

    if (needStopPropagation) {
      event.stopPropagation();
      event.preventDefault();
    }
  };

  const styles = custStyles || defautStyles;

  return (
    <Breakpoint>
      {({ sm }) => {
        return (
          <Element
            onKeyDown={handleKeyDown}
            className={styles.carouselPartialSlideRoot}
          >
            <Container>
              <Items
                onSwipe={handleIndexChange}
                injectedIndex={activeIndex}
                partialNextSlideWidth={sm ? partialSlideWidth : undefined}
                fullWidthMode={true}
              >
                {(slideList || []).map((item, i) => {
                  return (
                    <Item
                      key={`k${i}`}
                      index={i}
                      allowFocus={allowSlideFocus.current}
                    >
                      <Element
                        className={clsx(styles.itemWrapper, {
                          [styles.isActive]: i === currentIndex.current
                        })}
                        onClick={createOnItemClick(i)}
                      >
                        {item}
                      </Element>
                    </Item>
                  );
                })}
              </Items>
              <Element className={styles.controlWrap}>
                <Prev className={styles.paddleLeft} onClick={handleIndexChange}>
                  {buildPaddleIcon(true)}
                </Prev>
                <Element
                  className={styles.dotsWrap}
                  theme={{
                    margin: 0,
                    padding: 0,
                    textAlign: Align.Center
                  }}
                >
                  <Dots
                    onClick={handleIndexChange}
                    inactive={
                      <Element
                        Component="span"
                        className={styles.dot}
                        theme={{
                          marginLeft: 5,
                          marginRight: 5,
                          bgColor: Color.Gray04
                        }}
                      />
                    }
                    active={
                      <Element
                        Component="span"
                        className={styles.dot}
                        theme={{
                          marginLeft: 5,
                          marginRight: 5,
                          bgColor: Color.BrightBlue
                        }}
                      />
                    }
                    clickAble={true}
                  />
                </Element>
                <Next className={styles.paddleRight} onClick={handleIndexChange}>
                  {buildPaddleIcon(false)}
                </Next>
              </Element>
            </Container>
          </Element>
        );
      }}
    </Breakpoint>
  );
};

CarouselWithPartialNextSlide.defaultProps = {
  slideList: [],
  customization: {
    partialSlideWidth: 50,
    initialActiveItem: 0
  },
  rotationIndex: 0,
  onActiveIndexChange: () => {}
};

export default React.memo(CarouselWithPartialNextSlide);
