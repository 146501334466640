const breakpointMap = {
  MOBILE: 'sm',
  TABLET: 'md',
  DESKTOP: 'lg',
  XL: 'xl'
};

export default assets =>
  (assets || []).map(asset => ({
    ...asset,
    breakpoint: breakpointMap[asset.breakpoint.toUpperCase()] || asset.breakpoint
  }));
