import get from 'lodash.get';
import { createSelector } from 'src/universal/redux/utils';
import {
  getExperimentalPageData,
  getActivePageName,
  getPznOfferEligibleDtm
} from 'src/universal/redux/page';
import { hasPznDataArrived, getPznOfferList } from 'src/universal/redux/pznData';
import {
  getOneXpExperiments,
  hasOneXpRequestFinished
} from 'src/universal/redux/oneXp';

export const extractG136 = createSelector(getOneXpExperiments, tests => {
  const g136Test = tests['GCS-136-PznBadgeWordingTest'];
  const vKey = get(g136Test, 'dimensions[0].variant');
  if (
    typeof vKey === 'string' &&
    vKey.toLocaleLowerCase().indexOf('experiment') > -1
  ) {
    return { isVariant: true, key: vKey };
  }
  return { isVariant: false, key: '' };
});

/*
US4110464: important note:
(1) There is a chance that the following 2 selectors may remain even if g136 does not win:
  getSpecialOfferList
  getPznSpecialOfferDtmInfo
(2) They are located here now because of the g136 test data dependency. 

US4521826:
(1) need to make more liberal match for the issue of richer offers not being covered.
(2) matching logic is based on this Slack conversation:
https://aexp-mobile-and-web.slack.com/archives/G4XRGE072/p1694723275883249?thread_ts=1694627955.976559&cid=G4XRGE072
*/

export const getSpecialOfferList = createSelector(
  [hasPznDataArrived, getPznOfferList],
  (pznArrived, pznOfferList) => {
    if (!pznArrived) {
      return [];
    }

    const specialOfferIndicator = 'special';

    return pznOfferList.filter(offer => {
      const badgeTxt =
        typeof offer.offerButton === 'string' ? offer.offerButton : '';
      return new RegExp(`\\b${specialOfferIndicator}\\b`, 'i').test(badgeTxt);
    });
  }
);

export const getPznSpecialOfferDtmInfo = createSelector(
  [getPznOfferEligibleDtm, getSpecialOfferList],
  (dtmObj, offerList) => {
    if (!Array.isArray(offerList) || offerList.length <= 0) {
      return {
        needEvent: false,
        dtmObj
      };
    }

    const products = offerList.map(o => o.shortName);
    return {
      needEvent: true,
      dtmObj: {
        ...dtmObj,
        eventAsset: `pzn_special_offers_received_${products.join('_')}`
      }
    };
  }
);

export const getG136Data = createSelector(
  [
    extractG136,
    getExperimentalPageData,
    hasOneXpRequestFinished,
    hasPznDataArrived,
    getPznOfferList,
    getActivePageName
  ],
  (
    { isVariant, key },
    { g136PznBadgeWording = {} },
    oneXpDecided,
    pznArrived,
    pznOfferList,
    pageName
  ) => {
    let needToApplyG136ToCurrentPage = false;
    let cardInfoList = [];
    let matchedItem;

    const oneXpDecidedAndPznArrived = oneXpDecided && pznArrived;

    const isExcluded = shortName => {
      return (g136PznBadgeWording.exclusionList || []).indexOf(shortName) > -1;
    };

    if (oneXpDecidedAndPznArrived && isVariant) {
      matchedItem = (g136PznBadgeWording.content || []).find(
        item => item.key === key
      );
      if (matchedItem) {
        const g136Badge =
          typeof matchedItem.text === 'string' ? matchedItem.text : '';
        const g136BadgeNew =
          typeof matchedItem.replacedBy === 'string' ? matchedItem.replacedBy : '';
        cardInfoList = pznOfferList.map((offer, index) => {
          const offerBadge =
            typeof offer.offerButton === 'string' ? offer.offerButton : '';
          const shortName = offer.shortName;
          let badgeVal = offerBadge;
          let needG136Badge = false;
          if (
            !isExcluded(shortName) &&
            g136BadgeNew &&
            offerBadge &&
            g136Badge.toLowerCase().indexOf(offerBadge.toLowerCase()) > -1
          ) {
            if (pageName === 'businessPDP') {
              if (index === 0) {
                needToApplyG136ToCurrentPage = true;
              }
            } else {
              needToApplyG136ToCurrentPage = true;
            }
            badgeVal = g136BadgeNew;
            needG136Badge = true;
          }
          return {
            pmcCode: offer.pmcCode,
            shortName,
            index,
            badgeVal,
            needG136Badge
          };
        });
      }
    }

    return {
      oneXpDecidedAndPznArrived,
      needToApplyG136ToCurrentPage,
      cardInfoList,
      matchedItem
    };
  }
);
