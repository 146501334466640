import React from 'react';
import get from 'lodash.get';
import { connect } from 'react-redux';

import { getThreeUpData } from './redux';
import {
  Element,
  FontType,
  Color,
  Breakpoint,
  Box,
  Button,
  Typography
} from '@americanexpress/aqx-components';
import LazyScrollMetricTracker from 'src/universal/components/DTM/LazyScrollMetricTracker';
import { getUrlWithOriginPrepended } from 'core_modules/utils/url.utils';
import {
  mapUrlWithDtmLinkQuery,
  sendDTMClickEvent
} from 'service_modules/models/dtm-tagging';

import styles from './__styles__/index.scss?modules';

const ThreeUpCard = ({
  header,
  description,
  assets: [desktopAsset, tabletAsset],
  buttons
}) => {
  return (
    <Breakpoint>
      {({ sm }) => {
        if (sm) {
          return null;
        }

        let neededAsset;
        if (typeof window === 'object' && window.innerWidth >= 1024) {
          neededAsset = desktopAsset;
        } else if (typeof window === 'object' && window.innerWidth >= 768) {
          neededAsset = tabletAsset;
        } else {
          neededAsset = null;
        }

        return (
          <Box $border className={styles.ThreeUpBox}>
            <Element className={styles.imgWrapper}>
              <Element
                Component="img"
                src={neededAsset?.url}
                className={styles.mainImage}
                alt={neededAsset?.alt}
              />
            </Element>
            <div className={styles.textContent}>
              <Typography
                Component="h2"
                $family={FontType.HelveticaBold}
                $size={24}
                theme={{
                  color: Color.DeepBlue
                }}
                className={styles.header}
              >
                {header}
              </Typography>

              <Typography
                $family={FontType.HelveticaRegular}
                $size={18}
                $lineHeight={'125%'}
                theme={{
                  color: Color.DeepBlue
                }}
                className={styles.description}
              >
                {description}
              </Typography>

              {buttons.map((button, i) => {
                const { url, dtm } = button;
                const urlVal = mapUrlWithDtmLinkQuery(url, dtm);
                return (
                  <Button
                    Component="a"
                    href={getUrlWithOriginPrepended(urlVal)}
                    key={i}
                    className={styles.button}
                    aria-label={button.label}
                    onClick={() => sendDTMClickEvent(dtm)}
                  >
                    {button.text}
                  </Button>
                );
              })}
            </div>
          </Box>
        );
      }}
    </Breakpoint>
  );
};

const ThreeUp = ({ threeUpData }) => {
  return (
    <React.Fragment>
      <LazyScrollMetricTracker scrollDtm={get(threeUpData, 'dtmEvents[0]')} />
      <div className={styles.ThreeUp}>
        {(threeUpData?.content || []).map((cardData, i) => (
          <ThreeUpCard key={i} {...cardData} />
        ))}
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = state => {
  return {
    threeUpData: getThreeUpData(state)
  };
};

export default connect(mapStateToProps)(ThreeUp);
