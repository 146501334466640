import { createSelector } from 'reselect';
import {
  getPageData,
  getPageCardsData,
  getDtmImpressions
} from 'src/universal/redux/page';
import {
  getPznSpecialOffersOrdered,
  hasPznDataArrived,
  getPznSpecialOffers
} from 'src/universal/redux/pznData';

export const getSpecialOffersData = createSelector(
  [
    getPageData,
    getPageCardsData,
    hasPznDataArrived,
    getPznSpecialOffersOrdered,
    getDtmImpressions
  ],
  (pageData, cards, hasPznArrived, specialOffers, impressions) => {
    let needToRender = false;
    if (hasPznArrived && (specialOffers || []).length > 0) {
      needToRender = true;
    }
    const labelsHash = (pageData?.labels || []).reduce((accumulator, item) => {
      accumulator[item.identifier] = item.text;
      return accumulator;
    }, {});

    return {
      needToRender,
      specialOffersData: specialOffers,
      cards,
      labelsHash,
      pageId: 'open_businessHome',
      dtmList: impressions
    };
  }
);

export const needToRenderSpecialOffers = createSelector(
  [hasPznDataArrived, getPznSpecialOffers],
  (hasPznArrived, specialOffers) => {
    if (hasPznArrived && (specialOffers || []).length > 0) {
      return true;
    }
    return false;
  }
);
