import React from 'react';
import { connect } from 'react-redux';
import get from 'lodash.get';
import {
  Element,
  Color,
  Align,
  Size,
  FontType,
  Breakpoint,
  Typography,
  Icon
} from '@americanexpress/aqx-components';
import LazyScrollMetricTracker from 'src/universal/components/DTM/LazyScrollMetricTracker';
import { getPageData } from 'src/universal/redux/page';
import styles from './__styles__/index.scss?modules';

const MerchantCopy = ({ content, mode }) => {
  return (
    <Breakpoint>
      {({ sm }) => {
        if ((sm && mode !== 'mobile') || (!sm && mode !== 'desktop')) {
          return null;
        }

        return (
          <Element className={styles.merchantCopy}>
            <LazyScrollMetricTracker scrollDtm={get(content, 'dtmEvents[0]')} />
            <Icon
              className={styles.icon}
              directory="dls"
              hiddenToScreenReaders="true"
              imageName={content?.imageName}
              $size={Size.LG}
              theme={{
                color: Color.BrightBlue
              }}
            />
            <Typography
              className={styles.text}
              Component="h3"
              $family={FontType.HelveticaRegular}
              $size={sm ? '18px' : '20px'}
              $lineHeight="125%"
              theme={{
                textAlign: Align.Left
              }}
            >
              {content?.header}
            </Typography>
          </Element>
        );
      }}
    </Breakpoint>
  );
};

const mapStateToProps = state => ({
  content: getPageData(state)['merchantCopy']
});

export default connect(mapStateToProps)(React.memo(MerchantCopy));
