import React from 'react';
import { connect } from 'react-redux';
import RABBanner from 'src/universal/components/RABBanner';
import { getReferBusinessData } from './redux';
import LazyScrollMetricTracker from 'src/universal/components/DTM/LazyScrollMetricTracker';
import homepageRABBannerStyles from 'acq-components/lib/composite-components/HeroBanner/styles/ReinforcementBannerWithIcons.scss?modules';
import './__styles__/index.scss';

const ReferBusinessBanner = ({ content: { content, dtmEvents } }) => {
  return (
    <React.Fragment>
      <LazyScrollMetricTracker scrollDtm={(dtmEvents || [])[0]} />
      <RABBanner
        content={content}
        locale="homepage"
        styles={homepageRABBannerStyles}
      />
    </React.Fragment>
  );
};

const mapStateToProps = state => ({
  content: getReferBusinessData(state)
});

export default connect(mapStateToProps)(React.memo(ReferBusinessBanner));
