import React from 'react';
import { Element } from '@americanexpress/aqx-components';
import { Sanitizer } from 'acq-components/lib/base-components/Sanitizer/Sanitizer';

export const isStrikethroughOffer = offerHeader => offerHeader.indexOf('<s>') > -1;

export const StrikethroughOffer = ({ header, Component, className }) => {
  if (!header) {
    return null;
  }

  const shouldHandleStrikethrough = isStrikethroughOffer(header);

  if (!shouldHandleStrikethrough) {
    return (
      <Sanitizer
        Component={'h2'}
        className={className}
        safelySetInnerHTML={header}
      />
    );
  }

  const prevValueRegex = /<s>(.*?)<\/s>/;
  const prevValue = prevValueRegex.exec(header)?.[1];
  const newValueRegex = /<\/s>\s?(.*)/;
  const newValue = newValueRegex.exec(header)?.[1];
  const newValueWithStrippedTags = newValue.replace(/<[^>]*>/, '');

  const screenreaderText = `Earn, previously ${prevValue}, now ${newValueWithStrippedTags}`;

  return (
    <>
      <Element Component={Component} theme={{ visuallyHidden: true }}>
        {screenreaderText}
      </Element>
      <Sanitizer
        Component={'h2'}
        aria-hidden="true"
        className={className}
        safelySetInnerHTML={header}
      />
    </>
  );
};
