import React from 'react';

export const offerGatingForm = (
  <form
    name="pznApplicationForm"
    action=""
    method="post"
    id="pznApplicationForm"
    aria-hidden="true"
  >
    <input
      type="hidden"
      id="pznOfferGatingResp"
      name="pznOfferGatingResp"
      value=""
    />
    <input type="hidden" name="isPZNRequest" value="true" />
    <input type="hidden" name="isRicherOffer" value="" />
    <input type="hidden" id="gambitIndicator" name="gambitIndicator" value="" />
  </form>
);
