import React from 'react';
import { connect } from 'react-redux';
import clsx from 'clsx';
import { Element, Type, SelectionButtons } from '@americanexpress/aqx-components';
import replaceStringWithVars from 'src/universal/utils/replaceStringWithVars';
import {
  getDesignInfo,
  dispatchUpdateSelectedCardDesign,
  getSelectedColorInfo
} from 'src/universal/redux/cardColors';
import {
  getRubiksIndexByAssetId,
  getBubiksItemByAssetId,
  getRubiksAssetIdByIndex
} from 'src/universal/utils/cardColorChoices';
import { sendDTMClickEvent } from 'service_modules/models/dtm-tagging';
import styles from './__styles__/index.scss?modules';

export const parent = {
  HERO: 'pdpHero',
  SIDE_RAIL: 'pdpSideRail',
  VAC_TILE: 'vacTile',
  COMPARE: 'compare',
  RIB_MOBILE: 'pdpRibMobile',
  RIB_TABLET: 'pdpRibTablet',
  BHUB_OFFER: 'bHubSpecialOffer'
};

export const dtmPrefixHash = {
  [parent.HERO]: 'pdp_hero',
  [parent.SIDE_RAIL]: 'pdp_left_rail',
  [parent.VAC_TILE]: 'vac_tile',
  [parent.COMPARE]: 'compare',
  [parent.RIB_MOBILE]: 'pdp_reinforcement',
  [parent.RIB_TABLET]: 'pdp_reinforcement',
  [parent.BHUB_OFFER]: 'bHub_special_offer'
};

const buildColorClickDtm = (parentType, cardInfo, assetId) => {
  const rubiksItem = getBubiksItemByAssetId(cardInfo, assetId);
  const dtm = (rubiksItem?.dtmEvents || []).find(d => d.key === 'colorClick');
  return {
    ...(dtm || {}),
    eventAsset: replaceStringWithVars({
      varsObject: { prefix: dtmPrefixHash[parentType] },
      string: dtm.eventAsset || ''
    })
  };
};

const ColorPicker = ({ cardInfo, selectedColorInfo, updateIndex, parentType }) => {
  if (!cardInfo?.needColorPicker) {
    return null;
  }

  const assetId = selectedColorInfo ? selectedColorInfo.selectedAssetId : '';
  const currIndex = getRubiksIndexByAssetId(cardInfo, assetId);
  const pickerData = {
    colorChoices: (cardInfo?.rubiksContent?.content || []).map(c => {
      return {
        name: c.id,
        id: c.id,
        backgroundImage: c.appAssets?.backgroundImg
      };
    }),
    onChange: index => {
      const assetId = getRubiksAssetIdByIndex(cardInfo, index);
      updateIndex({
        targetCard: cardInfo.shortName,
        selectedAssetId: assetId
      });
      const dtm = buildColorClickDtm(parentType, cardInfo, assetId);
      sendDTMClickEvent(dtm);
    },
    selectedIndex: currIndex
  };

  const rubiksContent = cardInfo?.rubiksContent || {};
  const items = rubiksContent.content || [];
  const colorLabel = items[currIndex]?.label || '';
  const labelSuffix = items[currIndex]?.labelAddition || null;
  const parentTypeVal = parentType || parent.VAC_TILE;
  const flag = cardInfo?.rubiksContent?.header;

  return (
    <Element
      className={clsx(
        styles.colorPicker,
        styles[parentTypeVal],
        styles[cardInfo.shortName]
      )}
    >
      <Element className={styles.headerBox}>
        {rubiksContent.eyebrow && (
          <div className={styles.pickerEyebrow}>{rubiksContent.eyebrow}</div>
        )}
        <Element className={styles.labelWrapper}>
          {rubiksContent.title && (
            <div className={styles.pickerLabel}>{rubiksContent.title}</div>
          )}
          {flag && <Element className={styles.flag}>{flag}</Element>}
        </Element>
        <Element className={styles.colorLabel}>
          {colorLabel}
          {labelSuffix && <span className={styles.labelSuffix}>{labelSuffix}</span>}
        </Element>
      </Element>
      <SelectionButtons $type={Type.Secondary} data={pickerData} />
    </Element>
  );
};

const mapStateToProps = (state, ownProps) => {
  const { shortName } = ownProps || {};
  return {
    cardInfo: getDesignInfo(shortName)(state),
    selectedColorInfo: getSelectedColorInfo(shortName)(state)
  };
};

const mapDispatchToProps = dispatch => ({
  updateIndex: payLoad => dispatchUpdateSelectedCardDesign(dispatch, payLoad)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(React.memo(ColorPicker));
