import React from 'react';
import { Element } from '@americanexpress/aqx-components';
import Item from './ItemDisplay';
import styles from './__styles__/index.scss?modules';

const DesktopAndTablet = ({ itemList }) => {
  return (
    <Element className={styles.desktopWrap}>
      {(itemList || []).map((item, i) => {
        return <Item key={`d${i}`} {...item} />;
      })}
    </Element>
  );
};

export default React.memo(DesktopAndTablet);
