import { createSelector } from 'reselect';
import { getPageData } from 'src/universal/redux/page';

import { formatKabbageContent } from './utils';

export const getKabbageBannerData = createSelector([getPageData], pageData => {
  const contentVal = pageData?.kabbageLocBanner ?? {};
  const formattedContent = formatKabbageContent(contentVal);
  return {
    shouldRender: true,
    content: formattedContent
  };
});
