import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

// misc. shared and selectors
import { dispatchDtmActions } from 'src/universal/components/DispatchDtmActions';
import { pageLevelBundledCmPznExt } from 'src/universal/utils/orchestraCmPznExtEvent';
import { hasMemberInfoArrived } from 'src/universal/redux/cardMemberInfo.js';
import { needToRenderSpecialOffers } from './components/SpecialOffers/redux';
import { getPznSpecialOfferDtmInfo } from 'src/universal/components/Experiments/G136/redux';

// components
import { PageWrapper } from 'src/universal/components/PageWrapper';
import BusinessClassBanner from 'page_modules/business-hub/components/BusinessClassBanner';
import TermsAndConditions from 'page_modules/business-hub/components/TermsAndConditions';
import SuppCardsModal from 'src/universal/components/SuppModal';
import Hero from 'page_modules/business-hub/components/Hero';
import CorpStartupBanner from 'page_modules/business-hub/components/CorpStartupBanner';
import BankingProductsBanner from 'page_modules/business-hub/components/BankingProductsBanner';
import ThreeUp from 'page_modules/business-hub/components/ThreeUp';
import NoncardTwoUp from 'page_modules/business-hub/components/NoncardTwoUp';
import MobileAccordion from 'page_modules/business-hub/components/MobileAccordion';
import ReferBusinessBanner from './components/RABBanner';
import SpecialOffers from './components/SpecialOffers';
import MerchantCopy from './components/MerchantCopy';
import KabbageBanner from './components/KabbageBanner';
import LazyScrollMetricTracker from 'src/universal/components/DTM/LazyScrollMetricTracker';
import TrackExperimentImpression from 'src/universal/components/TrackExperimentImpression';

// utils
import { InitFetchPageDataHelper } from 'src/universal/redux/utils';

// styles
import './__styles__/navs.scss';
import { name as pageName, className } from './__styles__/pageVars.scss';

const FetchPageDataHelper = new InitFetchPageDataHelper({
  pageName,
  debugMode: false
});

const PageComponent = ({
  dispatch,
  data,
  cmInfoArrived,
  showSpecialOffers,
  pznSpecialOfferDtmInfo,
  pznData
}) => {
  const [isSuppModalVisible, toggleSuppModal] = useState(false);
  const handleToggleSuppModal = e => {
    e?.stopPropagation();
    toggleSuppModal(!isSuppModalVisible);
  };

  dispatchDtmActions({
    FetchPageDataHelper,
    dispatch,
    data,
    dtmCategoryPaths: [
      'page.dtm.pageName',
      'page.dtm.subCategory2',
      'page.dtm.subCategory3'
    ],
    usePZN: true,
    pznData: pznData
  });

  useEffect(
    () => {
      setTimeout(() => {
        pageLevelBundledCmPznExt();
      }, 200);
    },
    [cmInfoArrived]
  );

  return (
    <PageWrapper
      displayName={className}
      FetchPageDataHelper={FetchPageDataHelper}
      usePZN={true}
      data={data}
      shouldRenderOfferExpiry={showSpecialOffers}
    >
      {(pznSpecialOfferDtmInfo || {}).needEvent && (
        <LazyScrollMetricTracker
          scrollDtm={pznSpecialOfferDtmInfo.dtmObj}
          startTrackingAfterPznArrival={true}
        />
      )}
      <TrackExperimentImpression experimentName="GCS-153-Delta-RAM-2.0" />
      <Hero cmToolTipToggle={handleToggleSuppModal} />
      <ThreeUp />
      <MobileAccordion />
      <CorpStartupBanner />
      <MerchantCopy mode="desktop" />
      <KabbageBanner mode="desktop" />
      <BankingProductsBanner />
      <NoncardTwoUp />
      <MerchantCopy mode="mobile" />
      <SpecialOffers />
      <ReferBusinessBanner />
      <BusinessClassBanner />
      <TermsAndConditions />
      <SuppCardsModal
        showSuppCardsModal={isSuppModalVisible}
        toggle={handleToggleSuppModal}
        dtmElement={'hero'}
        dtmPage={'b2b'}
      />
    </PageWrapper>
  );
};

FetchPageDataHelper.pageDataSetup(PageComponent, className);

const mapStateToProps = state => {
  return {
    data: state[pageName],
    cmInfoArrived: hasMemberInfoArrived(state),
    showSpecialOffers: needToRenderSpecialOffers(state),
    pznSpecialOfferDtmInfo: getPznSpecialOfferDtmInfo(state),
    pznData: state.pznData
  };
};

export default connect(mapStateToProps)(PageComponent);
