import isOfferFromPzn from 'src/universal/utils/isOfferFromPzn';

const needPznEligibleEvent = (pznOffers, shortName) => {
  const pznCard = (pznOffers || []).find(c => c.shortName === shortName);
  if (pznCard) {
    return isOfferFromPzn(pznCard);
  }
  return false;
};

export default needPznEligibleEvent;
