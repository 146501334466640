import React from 'react';
import {
  Element,
  FontType,
  Align,
  Color,
  Type,
  Button,
  Typography
} from '@americanexpress/aqx-components';
import { Image } from 'acq-components/lib/base-components/Image/Image';
import { mapUrlWithDtmLinkQuery } from 'service_modules/models/dtm-tagging';
import LazyLoad from 'src/universal/components/DTM/LazyScrollMetricTracker';
import styles from './__styles__/index.scss?modules';

const ItemDisplay = ({ imageUrl, imageAlt, header, buttons }) => {
  const ctaObj = Array.isArray(buttons) ? buttons[0] || {} : {};
  const ctaUrl = mapUrlWithDtmLinkQuery(ctaObj.url, ctaObj.dtm);
  return (
    <Element className={styles.itemWrap}>
      <Element className={styles.imgWrap}>
        <LazyLoad>
          <Image
            className={styles.itemImage}
            src={imageUrl}
            aria-hidden="true"
            alt={imageAlt}
          />
        </LazyLoad>
      </Element>
      <Element className={styles.contentBox}>
        <Typography
          Component="h3"
          className={styles.itemHeader}
          $family={FontType.BentonRegular}
          $lineHeight="125%"
          theme={{
            textAlign: Align.Left,
            color: Color.Gray06,
            marginBottom: 10
          }}
        >
          {header}
        </Typography>
        <Button
          Component="a"
          className={styles.itemCta}
          $type={Type.Primary}
          href={ctaUrl}
          title={ctaObj.title}
          target="_blank"
          aria-label={ctaObj.label}
        >
          {ctaObj.title}
        </Button>
      </Element>
    </Element>
  );
};

export default React.memo(ItemDisplay);
